import { Provider } from "react-redux";
import store from "../global/utils/redux/store";
import App from "./App";
import QrRouter from "./QrRouter";
import InternetConnection from "../global/components/InternetConnection";
import { initJamezzPaths, routes } from "../global/utils/config";
import { SessionState } from "../kiosk/KioskRouter";
import { createRoot } from "react-dom/client";
import { ApplicationType } from "../global/utils/useSessionState";
import ErrorBoundary from "../global/components/ErrorBoundary";
import LiveInterface from "../global/components/dev/LiveInterface";

initJamezzPaths(routes.qr);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App themeMode={"dark"} applicationType={ApplicationType.QR}>
      <LiveInterface />
      <InternetConnection />
      <SessionState />
      <ErrorBoundary>
        <QrRouter />
      </ErrorBoundary>
    </App>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
